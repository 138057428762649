import * as React from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useParams } from "react-router";
import { currentPropertyID, currentPropertyQuery } from "../state/property";

const PropertySetter: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const { id } = useParams<{ id: string }>();
  const setCurrentProperty = useSetRecoilState(currentPropertyID);
  React.useEffect(() => {
    setCurrentProperty(id);
  }, [id, setCurrentProperty]);
  useRecoilValue(currentPropertyQuery);

  return <>{children}</>;
};

export default PropertySetter;
