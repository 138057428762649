import { format } from "date-fns";
import { request } from "../services/request";
import { EventEmitter } from "./events";

export type PropertyID = string | number;

const defaults = {
  fuelOut: 8,
  fuelIn: 8,
};

const initialState = {
  property: null,
  damages: [],
  turnover: null,
  overviewVideo: null,
  customerSignature: null,
  error: null,
  loading: false,
  saving: false,
  search: { name: "", contract: "", email: "" },
};

const state = {
  ...initialState,
};

const properties: Map<number, Property> = new Map();

export class Property extends EventEmitter {
  data?: Vehicle;
  get changes(): Change[] {
    return this.data?.changes ?? [];
  }

  get reports(): VehicleReport[] {
    return this.data?.vehicleReports ?? [];
  }

  get lastReport() {
    return this.reports?.[0]?.report;
  }

  get isCheckIn(): boolean {
    return !this.lastReport?.checked_in_at && !!this.lastReport?.checked_out_at;
  }

  get id(): PropertyID {
    return String(this.data?.asset?.id ?? this.data?.asset_id);
  }
  get vehicleId() {
    return this.data?.id;
  }
  get name() {
    return this.data?.asset?.name ?? "";
  }
  get fuelIn() {
    return defaults.fuelIn;
  }
  get fuelOut() {
    return (
      (this.isCheckIn ? this.data?.changes?.[0].fuel_out : defaults.fuelOut) ??
      defaults.fuelOut
    );
  }

  get milageIn() {
    return this.data?.changes?.[0].milage_in ?? 0;
  }

  get milageOut() {
    return this.isCheckIn
      ? this.data?.changes?.[0].milage_out ?? 0
      : this.data?.changes?.[0].milage_in ?? 0;
  }
  get registration() {
    return this.data?.license ?? "";
  }
  get category() {
    return this.data?.category ?? "";
  }
  get color() {
    return this.data?.color ?? "";
  }
  get fuelType() {
    return this.data?.fuel_type ?? "";
  }
  get driver() {
    return this.isCheckIn ? this.lastReport?.customer_name : state.search.name;
  }
  get email() {
    return this.isCheckIn
      ? this.lastReport?.customer_email
      : state.search.email;
  }
  get reservationNumber() {
    return this.isCheckIn
      ? this.lastReport?.reference_code
      : state.search.contract;
  }
  get isTransfer() {
    return this.lastReport?.transfer;
  }
  get location(): Location | undefined {
    return this.data?.asset.location;
  }

  get image(): string | undefined {
    return this.lastReport?.damages?.[0]?.images?.[0]?.src;
  }

  get flags() {
    return this.lastReport?.preparationFlags;
  }

  get failedFlags() {
    return this.flags?.filter((flag) => flag.check === false);
  }

  get damages() {
    return (this.lastReport?.damages ?? []).map((damage) => ({
      ...damage,
      date:
        damage.reported_at &&
        format(new Date(damage.reported_at), "MMM dd yyyy"),
      reported_at: damage.reported_at,
    }));
  }

  get nextAction() {
    if (this.isCheckIn) {
      if (this.isTransfer) {
        return "Transfer in";
      }

      return "Check in";
    } else {
      if (this.isTransfer) {
        return "Transfer out";
      }

      return "Check out";
    }
  }

  // get turnover() {
  //   return this.data?.preparationChecks ?? [];
  // }

  constructor(property?: Vehicle) {
    super();
    if (property) {
      this.data = { ...property };
    }
  }
}

export async function getProperty(id: number | string) {
  const realId = Number(id);
  if (!properties.has(realId)) {
    const fetched = await fetchProperty(id);
    if (fetched) {
      setProperty(new Property(fetched));
    }
  }

  return properties.get(realId);
}

export function setProperty(property: Property) {
  if (property && typeof property.vehicleId === "number") {
    properties.set(property.vehicleId, property);
  }
  return property;
}

export function setProperties(collection: Vehicle[]) {
  return collection.map((data) => setProperty(new Property(data)));
}

export async function fetchProperty(id: string | number) {
  const response: Vehicle = await request({
    endpoint: `vehicle-assets/${id}`,
  });
  return response;
}

export async function searchProperties(query: string): Promise<Property[]> {
  const response: Vehicle[] = await request({
    endpoint: `vehicle-assets?search=${encodeURIComponent(query)}`,
  });

  return setProperties(response);
}
